window.addEventListener("load", function () {
  $(".slick-slider").slick({
    infinite: true,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    dots:true
  });
  $(".ac_slick_wrapper").css("display", "unset");
  $(".loader").css("display", "none");
  $(".slick-slider").slick("slickGoTo", 0, true);

  var element = document.getElementById("slider");

  if (element){
    element.addEventListener("contextmenu", function (event) {
      event.preventDefault();
    });
  }
  
});
